// OpenAPIの設定を上書きするファイル (openAPIConfigOverride.ts)
import { Auth } from "aws-amplify";
import axios from "axios";

import config from "./config";
import { OpenAPI } from "./core/OpenAPI";

type Headers = Record<string, string>;

const axiosInstance = axios.create({
  timeout: 10000, // 10秒のタイムアウトを設定します
});

OpenAPI.BASE = config.API.endpoints[0].endpoint.replace(/\/$/, "");

// ヘッダーのリゾルバーを設定
OpenAPI.HEADERS = async (): Promise<Headers> => {
  const headers: Headers = {
    "Content-Type": "application/json",
  };
  try {
    const user = await Auth.currentSession();
    const idToken = user.getIdToken().getJwtToken();
    headers.Authorization = `Bearer ${idToken}`;
  } catch (error) {
    console.error("Failed to get the session for headers", error);
  }
  return headers; // headers オブジェクトを返す
};

(async () => {
  try {
    if (!OpenAPI.HEADERS) throw new Error("OpenAPI.HEADERS is not defined");
    const headers = OpenAPI.HEADERS;
    axiosInstance.defaults.headers.common = {
      ...axiosInstance.defaults.headers.common,
      ...headers,
    };
  } catch (error) {
    console.error("Error setting axios default headers:", error);
  }
})();
