import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import App from "./App";
import VehicleLocationMap from "./components/VehicleLocationMap";

// AppRouter コンポーネント
function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="" element={<App />} />
        <Route path="map" element={<VehicleLocationMap />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
