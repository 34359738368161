import "./../../css/VehicleLocationMap.css";
import "@aws-amplify/ui-react/styles.css";
import "./../../api/utils";

import { MapView } from "@aws-amplify/ui-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { Marker } from "react-map-gl";

import { ApiError, CurrentUser, Service, VehicleStatus } from "../../api";
import VanShuttleIcon from "../icons/VanShuttleIcon";
import VanShuttleIconShadow from "../icons/VanShuttleIconShadow";
import { COLOR_STYLES, MarkerSize } from "../VehicleLocationMap";
import Timer from "./Timer";

type Props = {
  markerSize: MarkerSize;
  errorStatus: (statusCode: number, message: string) => void;
};

const EXPO_MAP_IMAGES = [
  "/images/osaka_expo_nedo_xs.png",
  "/images/osaka_expo_nedo_s.png",
  "/images/osaka_expo_nedo_m.png",
  "/images/osaka_expo_nedo_l.png",
  "/images/osaka_expo_nedo_xl.png",
  "/images/osaka_expo_nedo_xxl.png",
];

// MapViewのズーム値の画像切り替えボーダー
const EXPO_MAP_IMAGE_ZOOM_BORDERS = [14, 15.2, 16, 17, 19, 23];

const calculateCentralCoordinate = (
  minLat: number,
  maxLat: number,
  minLon: number,
  maxLon: number,
  offsetX = 0,
  mapWidth: number,
) => {
  // 4隅の座標から中心座標を求める
  const centralLat = (minLat + maxLat) / 2;

  let centralLon = (minLon + maxLon) / 2;
  // オフセットを考慮して経度を調整
  centralLon = centralLon + (offsetX / mapWidth) * (maxLon - minLon);

  return {
    latitude: centralLat,
    longitude: centralLon,
  };
};

const calculateZoom = (
  minLat: number,
  maxLat: number,
  minLon: number,
  maxLon: number,
) => {
  // 地図上での緯度経度の範囲に基づいてズームレベルを計算する
  const latDiff = maxLat - minLat;
  const lonDiff = maxLon - minLon;

  // 緯度経度の差に基づいてズームレベルを推定する（地図ライブラリに依存）
  const zoomLat = 8 - Math.log(latDiff) / Math.log(2);
  const zoomLon = 8 - Math.log(lonDiff) / Math.log(2);

  // 地図の表示領域に収まる最小のズームレベルを採用する
  let zoomLevel = Math.min(zoomLat, zoomLon);
  // ズームレベルを0.25刻みに丸める
  zoomLevel = Math.floor(zoomLevel * 4) / 4;

  // 計算されたズームレベルが有効な範囲に収まるように調整する
  zoomLevel = Math.max(zoomLevel, 0); // ズームレベルの最小値
  zoomLevel = Math.min(zoomLevel, 18); // ズームレベルの最大値

  return zoomLevel;
};

const createInitialViewState = (
  vehicleStatuses: VehicleStatus[] | undefined,
  mapWidth: number,
) => {
  const cardWidth = mapWidth * 0.3;
  let minLat = Number.POSITIVE_INFINITY; // 最南端の緯度
  let maxLat = Number.NEGATIVE_INFINITY; // 最北端の緯度
  let minLon = Number.POSITIVE_INFINITY; // 最西端の経度
  let maxLon = Number.NEGATIVE_INFINITY; // 最東端の経度

  if (vehicleStatuses !== undefined && vehicleStatuses.length > 0) {
    vehicleStatuses.forEach((vehicleStatus) => {
      // 緯度経度が未定義の場合はスキップ
      if (
        vehicleStatus.latitude === undefined ||
        vehicleStatus.longitude === undefined
      ) {
        return;
      }

      minLat = Math.min(minLat, vehicleStatus.latitude);
      maxLat = Math.max(maxLat, vehicleStatus.latitude);
      minLon = Math.min(minLon, vehicleStatus.longitude);
      maxLon = Math.max(maxLon, vehicleStatus.longitude);
    });
  } else {
    // データがない場合、日本の座標を中心に表示
    minLat = 24.396; // 最南端の緯度
    maxLat = 45.522; // 最北端の緯度
    minLon = 122.934; // 最西端の経度
    maxLon = 153.986; // 最東端の経度
  }

  // 中心座標を計算
  const { latitude, longitude } = calculateCentralCoordinate(
    minLat,
    maxLat,
    minLon,
    maxLon,
    -cardWidth,
    mapWidth,
  );

  // ズームレベルを計算
  const zoom = calculateZoom(minLat, maxLat, minLon, maxLon);

  return {
    latitude: latitude,
    longitude: longitude,
    zoom: zoom,
  };
};

// 車両の情報によって色を変更する
const getColor = (vehicleStatus: VehicleStatus) => {
  if (!vehicleStatus.is_running) {
    return COLOR_STYLES["POWER_OFF"];
  } else if (vehicleStatus.operation_mode === "charging") {
    return COLOR_STYLES["CHARGING"];
  } else if (vehicleStatus.soc && vehicleStatus.soc <= 30) {
    return COLOR_STYLES["BATTERY_LOW"];
  } else {
    return "";
  }
};

function LocationMap({ markerSize, errorStatus }: Props) {
  const mapRef = useRef<HTMLDivElement>(null);

  const [vehicleStatuses, setVehicleStatuses] = useState<VehicleStatus[]>();
  const [mapWidth, setMapWidth] = useState(0);

  const loadedImages = useRef<boolean[]>();
  const zoomNum = useRef<number>(-1);
  const baseCanvasRefs = useRef<HTMLCanvasElement>();
  const images = useRef<HTMLImageElement[]>([]);

  const getVehicleStatuses = useCallback(async () => {
    try {
      // ログインユーザ情報取得
      const me: CurrentUser = await Service.getMe();

      // 車両情報取得
      const vehicleCurrentStatuses: VehicleStatus[] =
        await Service.getWebOrgsLocsDwptVehicleCurrentStatus({
          orgId: me.default_org_id || "",
          locId: me.default_loc_id || "",
        });

      // direction, longitude, latitudeが設定されているデータのみを抽出
      const filteredVehicleCurrentStatuses = vehicleCurrentStatuses.filter(
        (vehicleStatus) =>
          vehicleStatus.latitude !== undefined &&
          vehicleStatus.longitude !== undefined &&
          vehicleStatus.direction !== undefined,
      );

      setVehicleStatuses(filteredVehicleCurrentStatuses);
    } catch (error) {
      const apiError = error as ApiError;

      if (apiError.status) {
        errorStatus(apiError.status, "");
      } else {
        errorStatus(500, "");
      }
    }
  }, []);

  useEffect(() => {
    getVehicleStatuses();
  }, [getVehicleStatuses]);

  useEffect(() => {
    if (mapRef.current) {
      const { width } = mapRef.current.getBoundingClientRect();
      setMapWidth(width);
    }
  }, [mapRef.current]);

  // MapViewのズーム値を対応の解像度の画像インデックスへ変換
  const calcZoomNum = useCallback((zoom: number) => {
    for (let i = 0; i < EXPO_MAP_IMAGE_ZOOM_BORDERS.length; i++) {
      if (zoom < EXPO_MAP_IMAGE_ZOOM_BORDERS[i]) {
        return i;
      }
    }
    return 0;
  }, []);

  const drawCanvas = useCallback(
    (canvas: HTMLCanvasElement | undefined, img: HTMLImageElement) => {
      if (!canvas || !img) return;
      // 画像を読み込み後canvasに描画
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, img.width, img.height);
        ctx.drawImage(img, 0, 0, img.width, img.height);
        const steps = (img.width / canvas.width) >> 1;
        ctx.filter = `blur(${steps}px)`;
        ctx.imageSmoothingQuality = "low";
      }
    },
    [],
  );

  const loadImage = useCallback((index = 0) => {
    if (index < 0 || index >= EXPO_MAP_IMAGES.length) {
      return;
    }
    if (!loadedImages.current || loadedImages.current[index]) {
      drawCanvas(baseCanvasRefs.current, images.current[index]);
      return;
    }
    loadedImages.current[index] = true;
    const img = images.current[index];
    console.log(img);
    const baseCanvas: HTMLCanvasElement | undefined = baseCanvasRefs.current;

    img.onload = () => {
      if (!baseCanvas) return;
      drawCanvas(baseCanvas, img);
      images.current[index] = img;
    };
    img.src = EXPO_MAP_IMAGES[index];
  }, []);

  useEffect(() => {
    loadedImages.current = [];
    for (let i = 0; i < EXPO_MAP_IMAGES.length; i++) {
      images.current[i] = document.createElement("img");
      loadedImages.current[i] = false;
    }
    baseCanvasRefs.current = document.createElement("canvas");
  }, []);

  return (
    <Card ref={mapRef} className="main-card shadow-frame">
      {vehicleStatuses && (
        <>
          <Timer fn={getVehicleStatuses} />
          <MapView
            initialViewState={createInitialViewState(vehicleStatuses, mapWidth)}
            dragRotate={false}
            touchZoomRotate={false}
            onZoom={(e) => {
              const newZoom = calcZoomNum(e.viewState.zoom);
              if (zoomNum.current !== newZoom) {
                zoomNum.current = newZoom;
                loadImage(zoomNum.current);
              }
            }}
            onLoad={({ target }) => {
              zoomNum.current = calcZoomNum(target.getZoom());
              loadImage(zoomNum.current);

              // base
              const canvas = baseCanvasRefs.current;
              if (canvas) {
                target.addSource("Base", {
                  type: "canvas",
                  canvas,
                  coordinates: [
                    [135.372121, 34.658632], //左上
                    [135.399641, 34.658632], //右上
                    [135.399641, 34.642731], //右下
                    [135.372121, 34.642731], //左下
                  ],
                });

                target.addLayer({
                  id: "BaseLayer",
                  source: "Base",
                  type: "raster",
                  // maxzoomを22にすると最大ズーム時に画像が非表示になる
                  // maxzoomを23にしても最大ズーム値は22なので問題なし
                  maxzoom: 23,
                  minzoom: 0,
                });
              }
            }}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            {vehicleStatuses.map((vehicleStatus) => {
              const latitude = vehicleStatus.latitude || 0;
              const longitude = vehicleStatus.longitude || 0;
              const direction = vehicleStatus.direction || 0;

              return (
                <Marker
                  key={"marker_" + vehicleStatus.a_name}
                  latitude={latitude}
                  longitude={longitude}
                  style={{
                    fontSize:
                      markerSize === "small"
                        ? "20px"
                        : markerSize === "medium"
                        ? "30px"
                        : "40px",
                    color: getColor(vehicleStatus),
                  }}
                >
                  <div
                    style={{
                      transform: `rotate(${direction - 90}deg) ${
                        direction > 180 ? "scale(1, -1)" : "scale(1, 1)"
                      }`,
                    }}
                  >
                    {getColor(vehicleStatus) === "" ? (
                      <VanShuttleIcon />
                    ) : (
                      <VanShuttleIconShadow />
                    )}
                  </div>
                </Marker>
              );
            })}
          </MapView>
          <Card className="card-panel">
            <div className="card-title">車両一覧</div>

            <Table bsPrefix="card-table table">
              <thead className="table-header">
                <tr>
                  <td>
                    <p>車両名</p>
                  </td>
                  <td>
                    <p>状態</p>
                  </td>
                  <td>
                    <p>電池残量</p>
                  </td>
                  <td>
                    <p>充電電力</p>
                  </td>
                </tr>
              </thead>
              <tbody className="table-body">
                {vehicleStatuses &&
                  vehicleStatuses.map((vehicleStatus) => {
                    const bgColorKey = getColor(vehicleStatus);
                    return (
                      <tr key={"table_" + vehicleStatus.a_name}>
                        <td
                          style={{
                            backgroundColor: bgColorKey,
                          }}
                        >
                          <p>{vehicleStatus.a_name}</p>
                        </td>
                        <td
                          style={{
                            backgroundColor: bgColorKey,
                          }}
                        >
                          <p>
                            {vehicleStatus.operation_mode === undefined
                              ? "ー"
                              : vehicleStatus.operation_mode === "charging"
                              ? "充電中"
                              : "充電停止中"}
                          </p>
                        </td>
                        <td
                          style={{
                            backgroundColor: bgColorKey,
                          }}
                        >
                          <p>
                            {vehicleStatus.soc === undefined
                              ? "ー"
                              : vehicleStatus.soc + "%"}
                          </p>
                        </td>
                        <td
                          style={{
                            backgroundColor: bgColorKey,
                          }}
                        >
                          <p>
                            {vehicleStatus.charging_power_kw === undefined
                              ? "ー"
                              : vehicleStatus.charging_power_kw + "kW"}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </>
      )}
    </Card>
  );
}

export default LocationMap;
