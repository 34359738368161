import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const SquareIcon: React.FC = () => {
  return (
    <FontAwesomeIcon
      icon={faSquare}
      style={{ filter: "drop-shadow(0px 0px 1px black)" }}
    />
  );
};

export default SquareIcon;
