import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Spinner, Toast } from "react-bootstrap";

const Styles = () => (
  <style type="text/css">
    {`
      .timer-loading-toast {
        position: fixed;
        bottom: 0;
        right: 0;
        color: #fff;
        z-index: 100;
      }
    `}
  </style>
);

type Props = {
  fn: () => Promise<void>;
  interval?: number;
};

/**
 * @param fn: interval毎に呼ばれる処理
 * @param interval: (ms). default 60000 ms.
 */
const Timer: React.FC<Props> = (props: Props) => {
  const { fn, interval } = props;
  const time = useMemo(() => interval || 60000, [interval]); // default 60000 sec
  const [open, setOpen] = useState(false);

  const cb = useCallback(async () => {
    setOpen(true);
    await fn();
    setOpen(false);
  }, [fn]);

  useEffect(() => {
    const tm = setInterval(cb, time);
    return () => clearInterval(tm);
  }, [cb, time]);

  return (
    <>
      <Styles />
      <Toast
        bsPrefix="timer-loading-toast toast"
        bg="primary"
        show={open}
        animation
      >
        <Toast.Body>
          <Spinner as="span" animation="border" size="sm" role="status" />
          <span style={{ paddingLeft: "5px" }}>{` Loading...`}</span>
        </Toast.Body>
      </Toast>
    </>
  );
};

export default Timer;
