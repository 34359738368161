import "./../../css/VehicleLocationMap.css";

import React from "react";
import { Button, Modal } from "react-bootstrap";

import CircleExclamationIcon from "../icons/CircleExclamationIcon";

const Styles = () => (
  <style type="text/css">
    {`
        .error-modal-button {
          border: none;
          margin: 0.3125em;
          padding: 0.625em 2em;
          box-shadow: none;
          font-weight: 500;
          display: inline-block;
          background-color: rgb(221, 51, 51);
          color: #fff;
        }
        .error-modal {
          padding-top: 0px!important;
          padding-bottom: 14px!important;
        }
        .error-modal-content { 
          text-align: center;
          margin: 0px;
          padding: 5px 10px;
          font-size: 1.5rem;
          font-weight: 600;
        }
        .error-modal-icon {
          font-size: 0.9rem;
          font-weight: normal;
          background-color: red;
          border-color: red;
          color: red;
        }
        .error-modal-footer {
          justify-content: center;
          text-align: center;
          padding-top: 10px!important;
          padding-bottom: 14px!important;
          border-top: none;
        }
      `}
  </style>
);

const STATUS_ERROR_MESSAGE: { [key: number]: string } = {
  400: "問題が発生しました。\nブラウザを閉じて再アクセスしてください。",
  401: "問題が発生しました。\n再度ログインしてください。",
  403: "認証情報の有効期限が切れました。\n再度ログインしてください。",
  404: "該当データがありません。\nブラウザを閉じて再アクセスしてください。",
  422: "問題が発生しました。\nブラウザを閉じて再アクセスしてください。",
  500: "問題が発生しました。\nブラウザを閉じて再アクセスしてください。",
};

type Props = {
  errorMessage: string;
  status: number;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ErrorDialog: React.FC<Props> = (props: Props) => {
  const { errorMessage, status, open, setOpen } = props;

  return (
    <>
      <Styles />
      <Modal size="lg" show={open} centered>
        <Modal.Body bsPrefix={"error-modal"}>
          <div className="error-modal-content">
            <span className="error-modal-icon">
              <CircleExclamationIcon />
            </span>
            <br />
            <pre>
              {errorMessage === ""
                ? STATUS_ERROR_MESSAGE[status]
                : errorMessage}
            </pre>
          </div>
        </Modal.Body>

        <Modal.Footer bsPrefix={`error-modal-footer`}>
          <Button
            variant="error"
            bsPrefix="error-modal-button error-modal-button::hover btn"
            onClick={() => setOpen(false)}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ErrorDialog;
