import React from "react";

const Styles = () => (
  <style type="text/css">
    {`
      .circle-icon {
        position: relative;
        box-sizing: content-box;
        justify-content: center;
        width: 5em;
        height: 5em;
        margin: 1.25em auto 0.25em;
        zoom: normal;
        border: 0.25em solid;
        border-radius: 50%;
        line-height: 5em;
        cursor: default;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .circle-icon::before {
        font-size: 4rem;
        content: '!';
      }
    `}
  </style>
);

const CircleExclamationIcon: React.FC = () => {
  return (
    <>
      <Styles />
      <div className="circle-icon" />
    </>
  );
};

export default CircleExclamationIcon;
