import { faVanShuttle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const VanShuttleIconShadow: React.FC = () => {
  return (
    <FontAwesomeIcon
      icon={faVanShuttle}
      style={{ filter: "drop-shadow(0px 0px 1px black)" }}
    />
  );
};

export default VanShuttleIconShadow;
