/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CurrentUser } from "../models/CurrentUser";
import type { LocItem } from "../models/LocItem";
import type { OrgItem } from "../models/OrgItem";
import type { VehicleStatus } from "../models/VehicleStatus";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
export class Service {
  /**
   * 利用事業者一覧の取得
   * 利用事業者一覧を取得する
   * @returns OrgItem Successful Response
   * @returns any 4xx,5xxのエラー
   * @throws ApiError
   */
  public static getWebOrgs(): CancelablePromise<Array<OrgItem> | any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/web/orgs",
      errors: {
        403: `認証失敗`,
      },
    });
  }
  /**
   * ある利用事業者の情報の取得
   * 利用事業者IDが{org_id}の情報を取得する。
   * @returns OrgItem Successful Response
   * @returns any 4xx,5xxのエラー
   * @throws ApiError
   */
  public static getWebOrgs1({
    orgId,
  }: {
    orgId: string;
  }): CancelablePromise<OrgItem | any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/web/orgs/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        403: `認証失敗`,
      },
    });
  }
  /**
   * ある利用事業者の拠点一覧の取得
   * 利用事業者IDが{org_id}の拠点一覧を取得する。
   * @returns LocItem Successful Response
   * @returns any 4xx,5xxのエラー
   * @throws ApiError
   */
  public static getWebOrgsLocs({
    orgId,
  }: {
    orgId: string;
  }): CancelablePromise<Array<LocItem> | any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/web/orgs/{org_id}/locs",
      path: {
        org_id: orgId,
      },
      errors: {
        403: `認証失敗`,
      },
    });
  }
  /**
   * 車両の最新状態の取得
   * 利用事業者IDが{org_id}、拠点IDが{loc_id}の拠点と紐づけられている車両の最新状態を取得する。
   * @returns VehicleStatus Successful Response
   * @returns any 4xx,5xxのエラー
   * @throws ApiError
   */
  public static getWebOrgsLocsDwptVehicleCurrentStatus({
    orgId,
    locId,
  }: {
    /**
     * 利用事業者ID
     */
    orgId: string;
    /**
     * 拠点ID
     */
    locId: string;
  }): CancelablePromise<Array<VehicleStatus> | any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/web/orgs/{org_id}/locs/{loc_id}/dwpt_vehicle/current_status",
      path: {
        org_id: orgId,
        loc_id: locId,
      },
      errors: {
        403: `認証失敗`,
      },
    });
  }
  /**
   * 自身の情報の取得
   * API実行者自身のグループやログイン後に遷移するパスなどを応答する
   * @returns CurrentUser Successful Response
   * @returns any 4xx,5xxのエラー
   * @throws ApiError
   */
  public static getMe(): CancelablePromise<CurrentUser | any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/me",
      errors: {
        403: `認証失敗`,
      },
    });
  }
}
